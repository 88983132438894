import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index'
Vue.use(VueRouter)
export const routes = [
  {
    path: '/',
    name: 'main',
    component: () => import(/* webpackChunkName: "about" */ '@/views/index.vue')
  },
  {
    path: '/login',
    name: 'userLogin',
    component: () => import(/* webpackChunkName: "about" */ '@/views/Login.vue')
  },
  {
    path: '/share/shareVideo',
    name: "分享直播",
    component: () => import('@/views/share/shareVideo.vue'),
  },
  {
    path: '/share/shareModel',
    name: "分享模型",
    component: () => import('@/views/share/shareModel.vue'),
  },
]
export const asnycRoute=[
  {
    path: '/task/map',
    name: "航线库",
    component: () => import('@/views/task/map.vue'),
  },
  {
    path: '/task/addPlan',
    name: "添加任务",
    component: () => import('@/views/task/addPlan.vue'),
  },
  {
    path: '/task/mappingTask',
    name: "建图任务",
    component: () => import('@/views/task/mappingTask.vue'),
  },
  {
    path: '/task/taskLoop',
    name: "循环任务",
    component: () => import('@/views/task/taskLoop.vue'),
  },
  {
    path: '/task/flightTask',
    name: "待执行任务",
    component: () => import('@/views/task/flightTask.vue'),
  },
  {
    path: '/task/plan',
    name: "飞行记录",
    component: () => import('@/views/task/plan.vue'),
  },
  {
    path: '/task/dot',
    name: "打点记录",
    component: () => import('@/views/task/dot.vue'),
  },
  {
    path: '/control/myDevice',
    name: "我的设备",
    component: () => import('@/views/control/myDevice.vue'),
  },
  {
    path: '/control/remote',
    name: "我的设备",
    component: () => import('@/views/control/remote.vue'),
  },
  {
    path: '/control/deviceAlarm',
    name: "告警记录",
    component: () => import('@/views/control/deviceAlarm.vue'),
  },
  {
    path: '/media/AIRecord',
    name: "识别记录",
    component: () => import('@/views/media/AIRecord.vue'),
  },
  {
    path: '/media/flightReplay',
    name: "飞行回放",
    component: () => import('@/views/media/fightReplay.vue'),
  },
  {
    path: '/media/model',
    name: "模型查看",
    component: () => import('@/views/media/model.vue'),
  },
  {
    path: '/media/compress',
    name: "压缩记录",
    component: () => import('@/views/media/compress.vue'),
  },
  {
    path: '/user/userList',
    name: "用户管理",
    component: () => import('@/views/user/userList.vue'),
  },
  {
    path: '/user/userRole',
    name: "角色管理",
    component: () => import('@/views/user/userRole.vue'),
  },
  {
    path: '/user/actionLog',
    name: "操作日志",
    component: () => import('@/views/user/actionLog.vue'),
  },
  {
    path: '/user/deviceList',
    name: "设备管理",
    component: () => import('@/views/device/deviceList.vue'),
  },
  {
    path: '/user/orgList',
    name: "组织管理",
    component: () => import('@/views/cus/cusList.vue'),
  },
  {
    path: '/user/cusDevice',
    name: "组织管理",
    component: () => import('@/views/cus/cusDevice.vue'),
  },
  {
    path: '/user/projectList',
    name: "项目管理",
    component: () => import('@/views/user/project.vue'),
  }
]
export const asyncfixedRoute=[
  {
    path: '/home/main',
    name: '工作台',
    component: () => import(/* webpackChunkName: "about" */ '@/views/main.vue')
  },
  {
    path: '/user/personalCenter',
    name: "个人中心",
    component: () => import('@/views/user/personalCenter.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import('@/views/noPower.vue'),
    name: 'Any',//命名路由
  },
]
// export const asyncuserRoute=[

//   {
//     path: '/:pathMatch(.*)*',
//     component: () => import('@/views/noPower.vue'),
//     name: 'Any',//命名路由
//   },
// ]
function addNewRoute(route) {
  router.addRoute(route);
};
function getAsyncRoutes(menus, arr = []){
  menus?.map(menu => {
      // 检查当前菜单项是否在异步路由中
      let falg=asnycRoute.some(route => {
          return menu.menuName === route.name
      });
      if(falg){
        arr.push(menu)
      }
      // 如果菜单项有子菜单，递归检查子菜单
      if (menu.children && menu.children.length > 0) {
          getAsyncRoutes(menu.children, arr);
      }
  });
  return arr;
}
function getRouter(arr){
  arr?.map(menu => {
    // 检查当前菜单项是否在异步路由中
    asnycRoute.some(route => {
      if(menu.menuName===route.name){
        addNewRoute(route)
      }
    })
});
}
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes:routes,
})
// if(store.getters.getplatForm==2){
  let arr=getAsyncRoutes(JSON.parse(store.getters.getMenu));
  getRouter(arr);
  asyncfixedRoute.forEach(item=>{
    addNewRoute(item)
  })
  // addNewRoute(asnycRoute[0])
  // addNewRoute(asnycRoute[asnycRoute.length-1])
// }else if(store.getters.getplatForm==1){
//   asyncuserRoute.forEach(item=>{
//     addNewRoute(item)
//  })
// }
export default router
